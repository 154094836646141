import axios from 'axios';
import React, { useContext } from 'react'
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../context/CartContext';
import ConstantUrl from '../util/ConstantUrl';

export default function Login() {
    
    const cartState = useContext(CartContext);
        const URL = new ConstantUrl()
    const navigate = useNavigate();

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [isError, setIserror] = useState();
    const [errorshow, setErrorshow] = useState();


    const handleSubmit = (event) => {
      submitform(event);
    }

       async function submitform(e) {
          
             e.preventDefault();
          await axios.post(URL.loginUser,
            {
                 email: email,
                 password: password
            }).then((response) => {
               
                  const data = response.data['data'];

                if (response.data['error']) {
                
                    setErrorshow(data)
                    setIserror(true)
         
                } else {

                    localStorage.setItem("user", JSON.stringify(response.data['result']));
                    cartState.updateCart();
                    navigate('/home', { replace: true });
                    toast("Successfully Login.",{type:"success",theme:"dark"})
                }
          

                 });
     
    
    }
    

    return (
        <>
        
             {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Login</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                    LOGIN AREA
    ============================================  --}} */}
    <section id="login-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="login-block">
                        <div className="row">
                            <div className="col-lg-6 my-auto">
                                <div className="login-content">
                                    <div className="content-head">
                                        Welcome!
                                    </div>
                                    <div className="content-slogan">
                                        Amogh Ayurved
                                    </div>
                                    <div className="content-data">
                                                Amogh Ayurved is an Online Store to buy 100% Natural Herbal Tablets in India.
                                                The best remedy for healthy life.
                                    </div>
                                    <div className="redirect">
                                        New to Amogh Ayurved?
                                        <Link to="/register"> Register Here</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="login-form">
                                    <div className="form-head">
                                        Login
                                    </div>
                                    <div className="form">
                                        <form onSubmit={handleSubmit}>
                                            <div className="group-input">
                                                <label htmlFor="email">E-Mail</label>
                                                <input type="email" name="email" id="email" onChange={(e)=>setEmail(e.target.value)} placeholder="..."/>
                                            </div>
                                            <div className="group-input">
                                                <label htmlFor="password">Password</label>
                                                <input type="password" name="password" id="password"  onChange={(e)=>setPassword(e.target.value)} placeholder="..."/>
                                            </div>
                                            <div className="text-end"><Link to="/forgetpassword">Forgot Password?</Link></div>
                                                    { isError ? <p className="text-danger" > { errorshow}</p>:'' }   
                                            <div className="group-input">
                                                <input type="submit" value="Login" className="submit"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        </>
    )

}
