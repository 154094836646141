import React from 'react'
import { Outlet } from 'react-router-dom'
import { CartState } from '../context/CartState'
import Footer from './Footer'
import Navbar from './Navbar'

export default function Layout(props) {



  return (
    <>
      <CartState>
   
      <Navbar />
        
      <Outlet />
              
        <Footer />
             
    </CartState>
    </>
       
  )

}