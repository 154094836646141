import axios from 'axios';
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import ConstantUrl from '../util/ConstantUrl';

export default function Forgetpassword() {

   const URL = new ConstantUrl()
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [cpassword, setCpassword] = useState();
    const [isError, setIserror] = useState();
    const [errorshow, setErrorshow] = useState();
      const navigate = useNavigate();

    const handleSubmit = (event) => {

      submitform(event);
    }
    
    async function submitform(e) {
          
             e.preventDefault();
          await axios.post(URL.forgetPassword,
            {
                email: email,
               password: password
            }).then((response) => {
             
                  const data = response.data['message'];
                if (response.data['error']) {
                
                    setErrorshow(data)
                    setIserror(true)
         
                } else {
                    navigate('/login', { replace: true });
                    toast("Password has been changed succesfully.",{type:"success",theme:"dark"})
                }
          

    });
     
    
    }


    return (
  
    <>
          {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Forget Password</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                    REGISTER AREA
    ============================================  --}} */}
    <section id="login-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="login-block">
                        <div className="row">
                            <div className="col-lg-6 my-auto">
                                <div className="login-content">
                                    <div className="content-head">
                                        Welcome!
                                    </div>
                                    <div className="content-slogan">
                                        Amogh Ayurved
                                    </div>
                                    <div className="content-data">
                                                Amogh Ayurved is an Online Store to buy 100% Natural Herbal Tablets in India.
                                                The best remedy for healthy life.
                                    </div>
                                    <div className="redirect">
                                        Already have an account?  <Link to="/login">Login Here</Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="login-form">
                                    <div className="form-head">
                                        Forget Password
                                    </div>
                                    <div className="form">
                                        <form  onSubmit={handleSubmit}>
                                         
                                            <div className="group-input">
                                                <label htmlFor="email">E-Mail</label>
                                                        <input type="email" name="email" value={email} id="email" onChange={(e)=>setEmail(e.target.value)} placeholder="..." required/>
                                            </div>
                                            <div className="group-input">
                                                <label htmlFor="password">Password</label>
                                                <input type="password" name="password" value={password} id="password"  onChange={(e)=>setPassword(e.target.value)} placeholder="..." required/>
                                            </div>
                                            <div className="group-input">
                                                <label htmlFor="cpassword">Confirm Password</label> 
                                                        <input type="password" name="cpassword" value={cpassword} id="cpassword"
                                                    placeholder="..." onChange={(e)=>setCpassword(e.target.value)}  required/>
                                                    </div>
                                                    {cpassword ? cpassword=== password?'':<p className="text-danger" >Passwords do not match</p> :'' }   
                                                    { isError ? <p className="text-danger" > { errorshow}</p>:'' }   
                                            <div className="group-input">
                                                <input type="submit" value="Submit" className="submit"/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        </>
  )
}
