import axios from 'axios';
import React, {  useEffect, useState } from 'react'
import ConstantUrl from '../util/ConstantUrl';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';

export default function UserProfile() {
    const navigate = useNavigate();
    const URL = new ConstantUrl();
    var user = JSON.parse(localStorage.getItem("user"));
    
    const [profile, setProfile] = useState();
    const [orders, setOrders] = useState([]);

    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [mobile, setMobile] = useState(user.phone);
    const [address, setAddress] = useState(user.address);
    const [pincode, setPincode] = useState(user.pincode);

   

    const updateForm = async (e)  => {
        e.preventDefault();
        
        await axios.post(URL.profileUpdate,{user_id:user.id,name:name,email:email,pincode:pincode,address:address,phone:mobile}).then(response => {
            localStorage.setItem("user", JSON.stringify(response.data['data']));
            toast("Profile update succesfully.",{type:"success",theme:"dark"})
    
        });
    }

    async function fetchData() {
        
        await axios.post(URL.orderAllHistory,{user_id:user.id}).then(response => {
             
            setOrders(response.data['orderData'])

        })

    }

      const logout = (e) => {
       
        localStorage.removeItem('user');
    navigate('/login', { replace: true });
    }
    

    useEffect(() => {
      
        fetchData();

    }, [user]);


    if (user !== null) {
    

    return (
        <>
        
             {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Profile</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                USER PROFILE AREA
    ============================================  --}} */}
    <section id="user-profile-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="user-info-card">
                        <div className="row justify-content-around">
                            <div className="col-lg-3 my-auto">
                                <div className="user-img">
                                    <img src="assets/images/doctor/user.png" alt="..."
                                        className="w-100 h-100"/>
                                </div>
                            </div>
                            <div className="col-lg-8 ">
                                <div className="user-info">
                                    <div className="d-flex align-items-baseline">
                                        <div className="user-name">
                                            {user.name}
                                        </div>
                                        <div className="edit-btn" data-bs-toggle="modal" data-bs-target="#edit-user">
                                            <i className="fa-regular fa-pen-to-square"></i>
                                        </div>
                                    </div>
                                    <div className="user-detail">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="detail-bar">
                                                    <i className="fa-solid fa-phone"></i> &nbsp; {user.phone}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="detail-bar">
                                                    <i className="fa-regular fa-paper-plane"></i> &nbsp; {user.email}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="detail-bar">
                                                    <i className="fa-solid fa-signs-post"></i> &nbsp;
                                                    {user.address}
                                                </div>
                                            </div>
                                            <div className="col-lg-12">
                                                <div className="detail-bar">
                                                    <i className="fa-solid fa-arrow-right-from-bracket"></i> &nbsp;
                                                    <span role="button" onClick={logout} >Log Out</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section className="user-orders">
                        <div className="section-head">
                            <div className="heading">Orders</div>
                            <div className="heading-bottom">
                                <div className="line"></div>
                                <div className="seperator"></div>
                                <div className="line"></div>
                            </div>
                        </div>
                        <div className="row">
                                    
                                    { orders.length !==0 ? orders.map(data => 
                            
                                        
                                            data.order_items.map(item =>
                                                
                                                <>
                                                
                                                    
                                                      <div className="col-lg-6">
                                <div className="order-block">
                                    <div className="order-img">
                                        <img src={URL.productsPhotoUrl+item.image} alt="..."
                                            className="w-100 h-100"/>
                                    </div>
                                    <div className="order-info ">
                                        <div className="name text-truncate">{item.productname}.</div>
                                        <div className="price">Quantity. {item.quantity}</div>
                                        <div className="price">Rs. {item.sub_total}</div>
                                        <div className="status">Status : {item.active_status}</div>
                                        <div className="status">Date : { dateFormat(item.created_at,"mmmm dS, yyyy") }</div>
                                    </div>
                                </div>
                            </div>  
                                                
                                                </>
                                                
                                                
                                                
                                                
                                                )

                                    
                                        
                                      
                                        
                                        
                        ) :''}
                          
                            
                                </div>
                                </section>
                </div>
                
            </div>
        </div>
    </section>

    <div className="modal fade" id="edit-user">
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <form onSubmit={updateForm}>
                <div className="modal-header">
                    <h4 className="modal-title">Edit Info</h4>
                    <button type="button" data-bs-dismiss="modal" >
                        <i className="fa-solid fa-circle-xmark"></i>
                    </button>
                </div>
                <div className="modal-body" aria-hidden="true">
                    <div className="group-input">
                        <input type="text" name="name" value={name} placeholder="Your Name" onChange={e=> setName(e.target.value)} required/>
                    </div>
                    <div className="group-input">
                        <input type="number" name="number" placeholder="Phone Number" value={mobile} onChange={e=> setMobile(e.target.value)} required/>
                    </div>
                    <div className="group-input">
                        <input type="email" name="email" placeholder="E-Mail Address" value={email} onChange={e=> setEmail(e.target.value)} required/>
                    </div>
                    <div className="group-input">
                        <input type="text" name="pincode" placeholder="Pin Code" value={pincode} onChange={e=> setPincode(e.target.value)} />
                    </div>
                    <div className="group-input mb-0">
                                    <textarea name="address" placeholder="Delivery Address" onChange={e => setAddress(e.target.value)} required>{address}</textarea>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="submit" className="w-100" data-bs-dismiss="model" >Update</button>
                </div>
                </form>
            </div>
        </div>
    </div>
        
        
        </>
    )
      
    }

}
