import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import $ from 'jquery';
import { CartContext } from '../context/CartContext';
export default function Navbar() {

    const cartstate = useContext(CartContext);


    const user = JSON.parse(localStorage.getItem("user"));
    const [carts, setCarts] = useState([]);
    const [searchtext, setSearchText] = useState(null);

    const navigate = useNavigate();
   
    
    useEffect(() => {


        $(document).ready(function () {

            $(".mobile-menu").click(function () {

                $("#mobile-nav").animate({ right: "0" });


            });

            $(".close-mobile").click(function () {
                $("#mobile-nav").animate({ right: "-300px" });
            });

            $(".close-siderbar").click(function () {
                $("#mobile-nav").animate({ right: "-300px" });
            });

        });

            if (user !== null) {
               
                if (cartstate.cart !== null) {
        
                    setCarts(cartstate.cart)
                    
            }
            }


    }, [cartstate.cart]);

    const submittosearch = (e) => {

        if (searchtext === null) {
            navigate('/product-list/allproduct/null', { replace: true });
        } else {
            navigate('/product-list/search/' + searchtext, { replace: true });
        }


    }


    const logout = (e) => {
        localStorage.removeItem('user');
        cartstate.updateCart();
        navigate('/login', { replace: true });
        toast("Succesfully Logout.", { type: "success", theme: "dark" });

    }



    return (
        <>
           
            <ToastContainer />
            <header>
                <div className="header-top">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="contact-bar">
                                        <div className="mobile">
                                            <i className="fa-solid fa-phone"></i>
                                            &nbsp; +91 7887246298, 8542847236   &nbsp;   &nbsp;   &nbsp;
                                        </div>
                                        <div className="email">
                                            <i className="fa-regular fa-paper-plane"></i>
                                            &nbsp; info@amokhaherbal.com
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="social-bar">
                                        <a href="/#"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="/#"><i className="fa-brands fa-instagram"></i></a>
                                        <a href="/#"><i className="fa-brands fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-middle">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="header-container">
                                <div className="logo">
                                    <Link to="/"> <img src={`/assets/images/logo.png`} alt="..." className="w-100 h-100" /></Link>
                                </div>
                                <div className="nav-search-bar">
                                    <input type="text" name="search" placeholder="Search for Products here..." onChange={e => setSearchText(e.target.value)} ></input>
                                    <label role="button" onClick={submittosearch} htmlFor="search"><i className="fa-solid fa-magnifying-glass"></i></label>
                                </div>
                                <div className="mid-navs d-flex">
                                    {/* <div className="search-nav">
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                    </div> */}
                                    {/* <div className="cart-wish wishlist-nav">
                                <a href="wishlist"><i className="fa-regular fa-heart"></i></a>
                                <span className="counter">5</span>
                            </div> */}

                                    <div className="cart-wish cart-nav">
                                        <Link to="/cart">
                                            <img src={`/assets/images/shopping-cart.png`} alt="..." className="shopping-cart" />
                                        </Link>
                                        <span className="counter">{carts.length !== 0 ? carts.productcount : 0}</span>
                                    </div>

                                    <div className="cart-wish mobile-menu">
                                        <div>
                                            <i className="fa-solid fa-bars-staggered"></i>
                                        </div>
                                    </div>

                                    <div className="join">
                                        <div className="dropdown">
                                            <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                                &nbsp; {user === null ? <>

                                                    <i className="fa-solid fa-user"></i>&nbsp;<span class="d-lg-inline d-md-inline d-none">Login | Register</span>


                                                </> : user.name}
                                            </button>
                                            <ul className="dropdown-block">
                                                {
                                                    user === null ?
                                                        <>    <li><Link className="dropdown-item" to="/login">Login</Link></li>
                                                            <li><Link className="dropdown-item" to="/register">Register</Link></li>
                                                        </> :
                                                        <>

                                                            <li><Link className="dropdown-item" to="/user-profile">My Profile</Link></li>
                                                            <li><Link className="dropdown-item" to="/changepassword">Change password</Link></li>
                                                            <li><span className="dropdown-item" role="button" onClick={logout}>Log Out</span></li>
                                                        </>
                                                }

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="header-bottom">
                    {/* <div className="container-fluid">
                        <div className="header-container">
                            <div className="category-dropdown">
                                <div className="dropdown">
                                    <button type="button" className="dropdown-toggle" data-bs-toggle="dropdown">
                                        Categories
                                    </button>
                                    <ul className="dropdown-menu">
                                        {categories.map(cat =>
                                            <li key={cat.id}><a className="dropdown-item" href="/#">{cat.name}</a></li>
                                        )}

                                    </ul>
                                </div>
                            </div>
                            <div className="bottom-navs">
                                <ul>
                                    <li><Link to="home"><i className="fa-solid fa-house-chimney"></i> &nbsp; Home</Link></li>
                                    <li><Link to="about"><i className="fa-solid fa-building-wheat"></i> &nbsp; About Us</Link></li>
                                    <li><Link to="find-doctors"><i className="fa-solid fa-user-doctor"></i> &nbsp; Doctors</Link></li>
                                    <li><Link to="remedies"><i className="fa-solid fa-pills"></i> &nbsp; Remedies</Link></li>
                                    <li><Link to="contact"><i className="fa-solid fa-square-phone"></i> &nbsp; Contact Us</Link></li>


                                </ul>
                            </div>
                        </div>
                    </div> */}
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                </div>

            </header>

            {/* <!-- Search Popup --> */}
            {/* <div id="search-page" className={this.state.searchBar?'hidden':''} >
        <div className="search-block">
            <form action="#">
                <input type="text" name="search" id="search" placeholder="Search Here....."/>
                <label for="search"><i className="fa-solid fa-magnifying-glass"></i></label>
            </form>
        </div>
        <div className="close-search" onClick={this.closeSearch}>
            <i className="fa-solid fa-xmark"></i>
        </div>
    </div> */}


            {/* <!-- Sidebar Navigation --> */}
            <div id="mobile-nav" >
                <div className="mobile-head">
                    <div className="logo">
                        <img src="/assets/images/logo.png" alt="..." className="w-100 h-100" />
                    </div>
                    <div role="button" className="close-mobile">
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>
                <div className="mobile-body">
                    <div className="mobile-navs close-siderbar">
                        {/* <div className="mobile-nav-search">
                            <input type="text" name="search" placeholder="Search for Products here..."></input>
                            <label for="search"><i className="fa-solid fa-magnifying-glass"></i></label>
                        </div> */}
                        <div>
                            <Link to="/"><i className="fa-solid fa-house-chimney"></i> &nbsp; Home</Link>
                        </div>
                        {/* <div>
                            <a href="wishlist"><i className="fa-regular fa-heart"></i> &nbsp; Wishlist</a>
                        </div>
                        <div>
                            <a href="cart">
                                <img src={`/assets/images/shopping-cart.png`} alt="..." className="shopping-cart" />
                                &nbsp; Cart
                            </a>
                        </div> */}
                        <div className='close-siderbar'>
                            <Link to="/about"><i className="fa-solid fa-building-wheat"></i> &nbsp; About Us</Link>
                        </div>
                        <div >
                            <Link to="/find-doctors"><i className="fa-solid fa-user-doctor"></i> &nbsp; Doctors</Link>
                        </div>
                        <div>
                            <Link to="/remedies"><i className="fa-solid fa-pills"></i> &nbsp; Remedies</Link>
                        </div>
                        <div>
                            <Link to="/contact"><i className="fa-solid fa-square-phone"></i> &nbsp; Contact Us</Link>
                        </div>
                        <div>
                            <a href="mailto:info@amokhaherbal.com"><i className="fa-regular fa-paper-plane"></i> &nbsp;
                                info@amokhaherbal.com</a>
                        </div>
                        <div>
                            <a href="tel:+917887246298,"><i className="fa-solid fa-phone"></i> &nbsp; +91 7887246298</a>
                        </div>

                        {
                            user === null ?
                                <>

                                    <div>
                                        <Link to="/login"><i className="fa-solid fa-arrow-right-to-bracket"></i> &nbsp; Login</Link>
                                    </div>
                                    <div>
                                        <Link to="/register"><i className="fa-solid fa-user-plus"></i> &nbsp; Register</Link>
                                    </div>

                                </> :
                                <>
                                    <div>
                                        <Link to="/user-profile"><i className="fa-regular fa-circle-user"></i> &nbsp; My Profile</Link>
                                    </div>
                                    <div>
                                        <Link to="/changepassword"><i className="fa-regular fa-circle-user"></i> &nbsp; Change Password</Link>
                                    </div>
                                    <div onClick={logout}>
                                        <a href="/#"><i className="fa-solid fa-arrow-right-from-bracket"></i> &nbsp; Log Out</a>
                                    </div>



                                </>
                        }



                    </div>
                </div>
            </div>

        </>
    )

}
