import axios from 'axios';
import React, { Component } from 'react'
import ConstantUrl from '../util/ConstantUrl';
import { Parser } from 'html-to-react'
import { Link } from 'react-router-dom';
export default class Remedies extends Component {

       constructor(props) {
        super(props);
        this.state = {
            blogs:[]
        }
       this.URL = new ConstantUrl()
    }

   async componentDidMount() {

        
              const URL = new ConstantUrl();
        
      await  axios.post(URL.blogsAll).then((response) => {
            const blogs = response.data['data'];
   
          this.setState({ blogs });
    });

    
        
    }



  render() {
    return (
        <>
        
            {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Remedies</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                    REMEDIES AREA
    ============================================  --}} */}
    <section id="remedies-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="remedies-grid">
                                <div className="row">
                                    
                                    {this.state.blogs.map(blog =>
                                        
                                          <div className="col-lg-3 col-md-4 col-sm-6">
                                <Link to={`/remedy-details/${blog.id}`}>
                                    <div className="remedy-card">
                                        <div className="remedy-img">
                                            <img src={this.URL.blogsPhotoUrl+blog.image} alt="..."
                                                className="w-100 h-100"/>
                                        </div>
                                        <div className="remedy-content">
                                            <div className="remedy-head text-truncate">
                                                {blog.name}
                                            </div>
                                            <div className="remedy-data">
                                                {Parser().parse(blog.des)}
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>  
                                        
                                        
                                        )}
                        
                        
                           
                 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        </>
    )
  }
}
