import axios from 'axios';
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import ConstantUrl from '../util/ConstantUrl';
import { Parser } from 'html-to-react'


export default function RemedyDetails() {

    let { id } = useParams();
    const URL = new ConstantUrl();
    const [blog, setBlog] = useState([]);
    const [blogs, setBlogs] = useState([]);

    async function fetchData() {
         
      await axios.post(URL.blogItemdata,{blog_id:id}).then((response) => {
            
           const blog = response.data['data'];
         console.warn(blog);
          setBlog(blog);
       
    });

          await axios.post(URL.blogsAll).then((response) => {
            const blogs = response.data['data'];
          setBlogs(blogs);
    });

}

    useEffect(() => {
   fetchData()
}, []);

    return (
        <>
        
             {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Remedy Details</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                REMEDY DETAILS AREA
    ============================================  --}} */}
    <section id="remedy-detail-area">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="block">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="current-remedy">
                                    <div className="remedy-img">
                                        <img src={URL.blogsPhotoUrl+blog.image} alt="..."
                                            className="w-100 h-100"/>
                                    </div>
                                    <div className="remedy-head text-truncate">
                                        {blog.name}
                                    </div>
                                    <div className="remedy-details">
                                      {Parser().parse(blog.des)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="recent-remedy">
                                    <div className="head">
                                        <span>Recent Remedies</span>
                                    </div>
                                            <div className="remedy-grid">
                                                
                                                {blogs.map(blog =>
                                                    
                                                    
                                                        <Link to={`/remedy-details/${blog.id}`}>
                                            <div className="remedy">
                                                <div className="remedy-img">
                                                    <img src={URL.blogsPhotoUrl+blog.image} alt="..."
                                                        className="w-100 h-100"/>
                                                </div>
                                                <div className="remedy-detail">
                                                    <div className="remedy-name">
                                                        {blog.name}
                                                    </div>
                                                    <div className="remedy-desc">
                                                      {Parser().parse(blog.des)}
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                

                                                    
                                                    )}

                                    
                                
                                     
                                
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        
        </>
    )
  
}
