import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import ConstantUrl from '../util/ConstantUrl';

export default function ViewMore() {

  let { type } = useParams();
  
    const URL = new ConstantUrl();
    const [categories, setCategorie] = useState([]);
    const [brands, setBrands] = useState([]);
    
  useEffect(() => {

        async function fetchdata() {

            if (type === 'categories') {

                await axios.post(URL.categoryAll).then(response => {

                    setCategorie(response.data['data']);
                })

            } else if (type === 'brands') {
                await axios.post(URL.brandsAll).then(response => {
               
                    setBrands(response.data['data']);
                })

            }



        }

        fetchdata();

    }, []);

    if (type === "categories") {

        
  return (
    <>
    
            {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
            <section id="breadcrumb">
                <div className="container-fluid">
                    <div className="container">
                        <div className="breadcrumb-bar">
                            <div><Link to="/home">Home</Link></div>
                            <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                            <div>All Categories</div>
                        </div>
                    </div>
                </div>
                <hr className="m-0" />
            </section>

            {/* {{-- =============================================
                PRODUCT LIST AREA
    ============================================  --}} */}
            <section id="category-boxes" >
                <div className="section-gap">
                    <div className="container-fluid">
                        <div className="container" >
                            
                             <div className="row">

                                    {categories.map(categorie =>


                                        <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
                                            <div className="category-box">
                                                <Link to={`/product-list/category/${categorie.id}`}>
                                                    <div className="category-img">
                                                        <img src={`${URL.categoryPhotoUrl + categorie.image}`} alt="..." className="w-100 h-100" />
                                                    </div>
                                                    <div className="category-name text-truncate">
                                                        {categorie.name}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>


                                    )}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
    
    </>
  )
    } else if (type === "brands") {
        
              
  return (
    <>
    
            {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
            <section id="breadcrumb">
                <div className="container-fluid">
                    <div className="container">
                        <div className="breadcrumb-bar">
                            <div><Link to="/home">Home</Link></div>
                            <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                            <div>All Brands</div>
                        </div>
                    </div>
                </div>
                <hr className="m-0" />
            </section>

            {/* {{-- =============================================
                PRODUCT LIST AREA
    ============================================  --}} */}
            <section id="brand-boxes">
                <div className="section-gap">
                    <div className="container-fluid">
                        <div className="container">
                             <div className="row">

                                    {brands.map(brand =>


                                        <div className='col-lg-2 col-md-3 col-sm-4 col-6'>
                                            <div  className="brand-box">
                                                <Link to={`/product-list/brand/${brand.id}`}>
                                                    <div className="brand-img">
                                                        <img src={`${URL.brandPhotoUrl + brand.image}`} alt="..." className="w-100 h-100" />
                                                    </div>
                                                    <div className="brand-name text-truncat">
                                                        {brand.brand_name}
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>


                                    )}


                            </div>
                        </div>
                    </div>
                </div>
            </section>
    
    
    </>
  )

    } else {
        

    }


}
