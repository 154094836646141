import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ConstantUrl from '../util/ConstantUrl';

import { CartContext } from './CartContext';


export const CartState = (props) => {
    const URL = new ConstantUrl();
    const user = JSON.parse(localStorage.getItem("user"));
    const [cart, setCart] = useState(null);
   
    async function fetchdata() {

        if (user !== null) {
            await axios.post(URL.cartget, { user_id: user.id }).then((response) => {
              
                const data = response.data['data'];
                setCart(data)

            });
                    
        } else {
            setCart([]);
        }
    }
    useEffect(() => {

             
        fetchdata();
     
    },[]);

    const updateCart = () => {
        fetchdata();
    }

  return (
      <CartContext.Provider value={{ cart,updateCart }}>
          {props.children}
    </CartContext.Provider>
  )
}
