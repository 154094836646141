import axios from 'axios';
import React, { useContext } from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import useRazorpay from 'react-razorpay';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../context/CartContext';
import ConstantUrl from '../util/ConstantUrl';

export default function CheckOut() {


        const cartState = useContext(CartContext);

    const URL = new ConstantUrl();

    const [carts, setCarts] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        async function fetchData() {

            await axios.post(URL.cartget, { user_id: user.id }).then((response) => {
         
                const data = response.data['data'];
                setCarts(data)

            });


        }

        fetchData();

    }, []);

    const onSubmit = (event) => {
        event.preventDefault();
        setIsLoading(true)
        if (paymentMethod === 'COD') {
            submitform(event);
        } else if (paymentMethod === "Razorpay") {
            onlinePayment();
        }


    }

    const [name, setName] = useState(user.name);
    const [mobile, setMobile] = useState(user.phone);
    const [email] = useState(user.email);
    const [address, setAddress] = useState();
    const [pincode, setPinCode] = useState();
    const [paymentMethod, setPaymentMethod] = useState('COD');

    async function submitform(event) {

        await axios.post(URL.submitOrder, {
            user_id: user.id, mobile: mobile, user_name: name,
            address: address + ", " + pincode, payment_method: paymentMethod
        }).then((response) => {
            setIsLoading(false)
        
              cartState.updateCart();
            navigate('/thankyou', { replace: true });
            toast("Product ordered successfully.", { type: "success", theme: "dark" })
        });
        setIsLoading(false)
    }

    async function onlinefinalorder(p) {
        await axios.post(URL.submitOrder, {
            user_id: user.id, mobile: mobile, user_name: name,
            address: address + ", " + pincode, payment_method: paymentMethod, payment_id: p
        }).then((response) => {
            setIsLoading(false)
    
              cartState.updateCart();
            navigate('/thankyou', { replace: true });
            toast("Product ordered successfully.", { type: "success", theme: "dark" })
        });
        setIsLoading(false)
    }


    const Razorpay = useRazorpay();
    async function onlinePayment() {
        var orderid = "12345667"
        await axios.post(URL.ordercreateforreazorpay, { amount: carts.producttotalpricedata }).then(response =>
            orderid = response.data
        ); //  Create order on your backend

        const options = {
            key: "rzp_test_iu82sI2TZA9RoP",
            amount: (carts.producttotalpricedata * 100),
            currency: "INR",
            name: "Amoghayurved.com",
            description: "AmoghAyurved orders",
            image: "https://AmoghAyurved.com/assets/images/logo.png",
            order_id: orderid, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
            handler: function (response) {

                onlinefinalorder(response.razorpay_payment_id)
                //   alert(response.razorpay_payment_id);
                //   alert(response.razorpay_order_id);
                //   alert(response.razorpay_signature);
            },
            prefill: {
                name: name,
                email: email,
                contact: mobile,
            },
            notes: {
                address: address,
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            alert(response.error.reason);
            toast("Something went wrong.", { type: "success", theme: "dark" })
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
        });

        rzp1.open();

    }


    if (carts.length !== 0) {

        return (
            <>
                {isLoading ? <div classNameName="loading-overlay"></div> : ''}
                {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
                <section id="breadcrumb">
                    <div className="container-fluid">
                        <div className="container">
                            <div className="breadcrumb-bar">
                                <div><Link to="/home">Home</Link></div>
                                <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                                <div>Checkout</div>
                            </div>
                        </div>
                    </div>
                    <hr className="m-0" />
                </section>

                {/* {{-- =============================================
                    CHECKOUT AREA
    ============================================  --}} */}
                <section id="checkout-area">
                    <div className="section-gap">
                        <div className="container-fluid">
                            <div className="container">
                                <div className="checkout-area">
                                    <form onSubmit={onSubmit}>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="billing-detail">
                                                    <div className="head">
                                                        Billing Details
                                                    </div>
                                                    <div className="billiing-form">
                                                        <div className="group-input">
                                                            <input type="text" name="name" value={name} onChange={(e) => setName(e.target.value)} placeholder="Your Name..." required />
                                                        </div>
                                                        <div className="group-input">
                                                            <input type="number" name="phone" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Phone Number..." required />
                                                        </div>
                                                        <div className="group-input">
                                                            <input type="email" name="email" disabled value={email} placeholder="E-Mail Address..." required />

                                                        </div>
                                                        <div className="group-input">
                                                            <input type="text" name="address" value={address} placeholder="Delivery Address." onChange={(e) => setAddress(e.target.value)} required />
                                                        </div>

                                                        <div className="group-input">
                                                            <input type="number" name="pincode" onChange={(e) => setPinCode(e.target.value)} placeholder="Pin Code..." required />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="your-order">
                                                    <div className="head">
                                                        Your Order
                                                    </div>
                                                    <div className="order-table">
                                                        <div className="block">
                                                            <div className="product-block bold-block">
                                                                PRODUCT
                                                            </div>
                                                            <div className="price-block bold-block">
                                                                TOTAL
                                                            </div>
                                                        </div>
                                                        {
                                                            carts.cartitemsdata.map(item =>
                                                                <div className="block ">
                                                                    <div className="product-block text-truncate">
                                                                        {item.products_details.name}
                                                                    </div>
                                                                    <div className="price-block">
                                                                        Rs.{item.price}
                                                                    </div>
                                                                </div>

                                                            )
                                                        }

                                                        <div className="block ">
                                                            <div className="product-block text-truncate bold-block">
                                                                Sub Total
                                                            </div>
                                                            <div className="price-block">
                                                                Rs.{carts.productsubtotaldata}
                                                            </div>
                                                        </div>
                                                        <div className="block ">
                                                            <div className="product-block text-truncate bold-block">
                                                                Delivery Charge
                                                            </div>
                                                            <div className="price-block">
                                                                Rs.{carts.deliverchargedata}
                                                            </div>
                                                        </div>
                                                        <div className="block ">
                                                            <div className="product-block text-truncate bold-block">
                                                                Final Total
                                                            </div>
                                                            <div className="price-block">
                                                                Rs.{carts.producttotalpricedata}
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="block">
                                                        <input type="radio" id='cod' name="payment_method" onChange={(e) => setPaymentMethod(e.target.value)} value="COD" checked={paymentMethod === "COD"} />
                                                        &nbsp; &nbsp;<label for="cod">Cash On Delivery</label><br></br>

                                                    </div>
                                                    <div className="block">
                                                        <input type="radio" id='online' name="payment_method" onChange={(e) => setPaymentMethod(e.target.value)} value="Razorpay" checked={paymentMethod === "Razorpay"} />
                                                        &nbsp; &nbsp; <label for="online">Online(Card/UPI/NetBanking)</label><br></br>

                                                    </div>
                                                    <br></br>

                                                    <div className="place-order-btn">
                                                        <button className="w-100" type='submit'>Place Order</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </>
        )
    }


}
