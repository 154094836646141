import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CartContext } from '../context/CartContext';
import ConstantUrl from '../util/ConstantUrl';

export default function Cart() {

     const cartstate = useContext(CartContext);

    const URL = new ConstantUrl();

    const [carts, setCarts] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));

   async function fetchData() {
       
       await axios.post(URL.cartget, { user_id: user.id }).then((response) => {
         
           const data = response.data['data'];
           setCarts(data)
           
       });
       
    }
    
    const cartItemDelete = id => event => {
        
     axios.post(URL.cartdeleteItem, { cart_id: id, user_id: user.id }).then((response) => {
        
         fetchData();
         cartstate.updateCart();
            toast("Succesfully deleted cart item.",{type:"success",theme:"dark"})
     });
    
    
    }


   const increaseCart = id => event =>{
    
       axios.post(URL.cartQuantityAdd, { cart_id: id, user_id: user.id }).then((response) => {
   
           fetchData();
           
       });
    
    }
    
   const decreaseCart = id => event => {
         axios.post(URL.cartQuantityRemove, { cart_id: id, user_id: user.id }).then((response) => {
           
               cartstate.updateCart();
                fetchData();
       });
    }


    useEffect(()=>{

        fetchData();

    }, []);


    if (carts.length !== 0) {
        
        
    return (
        <>
        
         {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><Link to="/home">Home</Link></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Cart</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
    </section>

    {/* {{-- =============================================
                    WISHLIST AREA
    ============================================  --}} */}
    <section id="cart">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="cart-product-table">
                        <div className="table-responsive">
                            <table className="table table-success table-bordered">
                                <thead>
                                    <tr>
                                        <th>&nbsp;</th>
                                        <th className="text-center">Product</th>
                                        <th className="text-center">Product Name</th>
                                        <th className="text-center">Product MRP</th>
                                        <th className="text-center">Product Price</th>
                                        <th className="text-center">Quantity</th>
                                        <th className="text-center">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                            {carts.cartitemsdata.map(item =>
                                    
                                    
                                               <tr>
                                        <td>
                                          <div> <i role="button" className="fa-solid fa-trash-can p-3" onClick={cartItemDelete(item.id)}></i> </div>
                                        </td>
                                        <td>
                                            <div>
                                                <Link to={`/product-details/${item.products_details.id}`}>
                                                    <img src={URL.productsPhotoUrl+item.products_details.image}
                                                        alt=""/>
                                                </Link>
                                            </div>
                                        </td>
                                        <td>
                                            <div>{item.products_details.name}</div>
                                        </td>
                                        <td>
                                            <div>Rs.{item.mrp}</div>
                                        </td>
                                        <td>
                                            <div>Rs.{item.price}</div>
                                        </td>
                                        <td>
                                            <div>
                                                <div className="cart-plus-minus">
                                                    <input disabled className="cart-plus-minus-box" value={item.quantity} type="text"/>
                                                    <div onClick={decreaseCart(item.id)} className="dec qtybutton"><i className="fa fa-angle-down"></i></div>
                                                    <div onClick={increaseCart(item.id)} className="inc qtybutton"><i className="fa fa-angle-up"></i></div>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>Rs.{item.total}</div>
                                        </td>
                                    </tr>         
                                                
                                    
                                    )}
                            
                                 
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="cart-bill-table">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-10 col-12 ms-auto">
                                <div className="table-responsive">
                                    <table className="table table-borderless">
                                        <tbody>
                                            <tr>
                                                <th>Subtotal</th>
                                                        <td className="text-end">Rs.{ carts.productsubtotaldata}</td>
                                            </tr>
                                            <tr>
                                                <th>Discount</th>
                                                        <td className="text-end">Rs.{ carts.productdiscount}</td>
                                            </tr>
                                            <tr>
                                                <th>Delivery Charge</th>
                                                        <td className="text-end">Rs.{ carts.deliverchargedata}</td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                        <td className="text-end">Rs.{ carts.producttotalpricedata}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="place-order-btn">
                                    <Link to="/checkout"><button>Proceed to Checkout</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
        
        </>
    )



    } else {
        

        return <>
     
                {/* {{-- =============================================
                    BREADCRUMB
    ============================================  --}} */}
    <section id="breadcrumb">
        <div className="container-fluid">
            <div className="container">
                <div className="breadcrumb-bar">
                    <div><a href="/">Home</a></div>
                    <div>&nbsp; <i className="fa-solid fa-minus"></i> &nbsp;</div>
                    <div>Cart</div>
                </div>
            </div>
        </div>
        <hr className="m-0"/>
            </section>
            

            
    {/* {{-- =============================================
                    WISHLIST AREA
    ============================================  --}} */}
    <section id="cart">
        <div className="section-gap">
            <div className="container-fluid">
                <div className="container">
                    <div className="cart-product-table bg-back p-5">
                         <center>
                            <label>Cart is Empty</label> 
                        </center>
                    </div>

                
                </div>
            </div>
        </div>
    </section>
        
        </>

    }



}
