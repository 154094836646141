import React, { useContext } from 'react'
import axios from "axios";
import ConstantUrl from '../util/ConstantUrl';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Parser } from 'html-to-react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { useState } from 'react';
import { CartContext } from '../context/CartContext';

export default function Home() {


  const navigate = useNavigate();
  const cartstate = useContext(CartContext);
    const URL = new ConstantUrl();
    var user = JSON.parse(localStorage.getItem("user"));

    const [categories, setCategorie] = useState([]);
    const [brands, setBrands] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [sliders, setSliders] = useState([]);
    const [products, setProducts] = useState([]);
    const [banners, setBanners] = useState([]);
    const [doctors, setDoctors] = useState([]);


    useEffect(() => {


        async function fetchdata() {


            await axios.post(URL.teamsAll).then((response) => {
                const teams = response.data['data'];
                setDoctors(teams);
            });


            await axios.post(URL.homeslidersAll).then((response) => {
                const sliders = response.data['data'];
                setSliders(sliders);
            });


            await axios.post(URL.categoryAll).then((response) => {
                const categories = response.data['data'];

                setCategorie(categories);
            });

            await axios.post(URL.brandsAll).then((response) => {
                const brands = response.data['data'];

                setBrands(brands);
            });

            await axios.post(URL.blogsAll).then((response) => {
                const blogs = response.data['data'];

                setBlogs(blogs);
            });

            await axios.post(URL.productAll).then((response) => {
                const products = response.data['product'];

                setProducts(products);
            });

            await axios.post(URL.bannerAll).then((response) => {
                const banners = response.data['data'];

                setBanners(banners);
            });

        }

        fetchdata();

    }, [URL.bannerAll, URL.blogsAll, URL.brandsAll, URL.categoryAll, URL.homeslidersAll, URL.productAll, URL.teamsAll]);

    const [variant_id, setVariantId] = useState();
    const [product_id, setProductId] = useState();


    const onchangeoption = (id) => (event) => {

        setProductId(id);
        setVariantId(event.target.value);

    }


    const submitEvent = (id, v_id) => (event) => {

        event.preventDefault();
        if (user === null) {
              navigate('/login', { replace: true });
            toast('Login First', { type: "error", theme: "dark" })
        }

        axios.post(URL.cartAdd, {
            product_id: id, variant_id: variant_id ? product_id === id ? variant_id : v_id : v_id,
            quantity: 1, user_id: user.id
        }).then((response) => {
            cartstate.updateCart();
        
            if (response.data['error']) {
                toast(response.data['message'], { type: "error", theme: "dark" })
            } else {
                toast("Product added in cart", { type: "success", theme: "dark" })
            }

        });

    }

    const forHomeSlider = {

        nav: true,
        dots: false,
        loop: true,
        autoplay: true,
        navText: ['<i class="fa fa-chevron-left "></i>', '<i class="fa fa-chevron-right"></i>'],
        smartSpeed: 500,
    };

    const forcategory = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: ['<i class="fa fa-chevron-left "></i>', '<i class="fa fa-chevron-right"></i>'],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            576: {
                items: 3,



            },
            768: {
                items: 4,
            },
            991: {
                items: 5,
            },
            1200: {
                items: 6,
            }
        },
    };


    const fordoctor = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            991: {
                items: 4,
            },
            1200: {
                items: 5
            }
        },
    };


    const forRemedy = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            991: {
                items: 4,
            },
            1200: {
                items: 5
            }
        },
    };


    const forProducts = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        loop: false,
        dots: false,
        autoplay: true,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 2,
            },
            400: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            991: {
                items: 4,
            },
            1200: {
                items: 5
            }
        },
    };


    const forBrands = {
        margin: 30,
        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: true,
        navText: ['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>'],
        smartSpeed: 500,
        responsive: {
            0: {
                items: 2,
            },
            576: {
                items: 3,
            },
            768: {
                items: 4,
            },
            991: {
                items: 5,
            },
            1200: {
                items: 6,
            }
        },
    };


    return (
        <>
          

            {/* {{-- =============================================
                    MAIN BANNER
    ============================================  --}}           */}
            <section id="main-slider">
                
                <div className="slider-area">
                    <div className="home-slider">


                        {/* <Homeslider sliders={this.state.sliders} /> */}

                        <OwlCarousel items={1}

                            className="owl-theme"
                            {...forHomeSlider}
                            margin={8} >

                            {sliders.map(slider =>

                                <div key={slider.id} className="slider-item">
                                    <div className="slider-img">
                                        <img src={URL.homesliderPhotoUrl + slider.image} alt="..." className="w-100 h-100" />
                                    </div>
                                </div>

                            )}

                        </OwlCarousel>


                    </div>
                </div>
            </section>

            {/* 
            {{-- =============================================
                            CATEGORY BOXES
            ============================================  --}} */}


            <section className="home-section" id="category-boxes">
                <div className="container-fluid">
                    <div className="container">
                        <div className="section-head">
                            <div className="heading head-1">Popular Categories</div>
                            <Link className="view-more" to="/viewmore/categories">
                                View More
                            </Link>
                        </div>
                        <div className="category-grid">
                            <div className="row">

                                <OwlCarousel

                                    className="owl-theme"
                                    {...forcategory}
                                    margin={8}
                                >


                                    {categories.slice(0, 10).map(categorie =>



                                        <div key={categorie.id} className="category-box">
                                            <Link to={`/product-list/category/${categorie.id}`}>
                                                <div className="category-img">
                                                    <img src={`${URL.categoryPhotoUrl + categorie.image}`} alt="..." className="w-100 h-100" />
                                                </div>
                                                <div className="category-name text-truncate">
                                                    {categorie.name}
                                                </div>
                                            </Link>
                                        </div>



                                    )}

                                </OwlCarousel>




                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* 
    {{-- =============================================
                    PRODUCT BOXES
    ============================================  --}} */}

            <section className="home-section bg-back" id="ayurveda-products">
                <div className="container-fluid">
                    <div className="container">
                        <div className="section-head">
                            <div className="heading head-2">Our Top Products</div>
                            <Link className="view-more" to="/product-list/allproduct/null">
                                View More
                            </Link>
                        </div>
                        <div className="product-grid">
                            <div className="product-carousel">


                                <OwlCarousel items={4}

                                    className="owl-theme"
                                    {...forProducts}
                                    margin={8}
                                >

                                    {products.slice(0, 10).map(product =>


                                        <div key={product.id} className="product-card">
                                            <div className="product-img">
                                                <Link to={`/product-details/${product.id}`} className="d-block w-100 h-100">
                                                    <img src={URL.productsPhotoUrl + product.image} alt="..."
                                                        className="w-100 h-100" />
                                                </Link>
                                            </div>
                                            <div className="product-info">
                                                <div>
                                                    <div className="product-name text-truncate">{product.name}</div>
                                                    <div className="price">
                                                        Rs.  {product.product_variant[0].discounted_price} <del>Rs.{product.product_variant[0].price}</del>
                                                    </div>
                                                </div>
                                                <div className="variant-bar">
                                                    <select name="variant" onChange={onchangeoption(product.id)} className="w-100">
                                                        {product.product_variant.map(pv =>
                                                            <option value={pv.id} >{pv.qty} {pv.uname} -  Rs. {pv.discounted_price} </option>
                                                        )}


                                                    </select>
                                                </div>
                                                <div className="cart-btn">
                                                    <button onClick={submitEvent(product.id, product.product_variant[0].id)}> <img src={`/assets/images/shopping-cart.png`} alt="..." className="shopping-cart" /> &nbsp; Add </button>
                                                </div>
                                            </div>
                                            {/* <button className="wishlist-btn"><i className="fa-solid fa-heart"></i></button> */}
                                        </div>



                                    )}

                                </OwlCarousel>




                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* 
    {{-- =============================================
                        BANNERS
    ============================================  --}} */}

            <section className="home-section banners" id="banners">
                <div className="container-fluid">
                    <div className="container">
                        <div className="row">

                            {banners.map(banner =>

                                <div key={banner.id} className="col-lg-6">
                                    <div className="banner">
                                        <a href="/#">
                                            <img src={URL.bannerPhotoUrl + banner.image} alt="..."
                                                className="w-100 h-100" />
                                        </a>
                                        <div className="banner-content">
                                            <div className="banner-head">
                                                {banner.title}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            )}



                        </div>
                    </div>
                </div>
            </section>

            {/* {{-- =============================================
                    BRAND BOXES
    ============================================  --}}         */}

            <section className="home-section bg-back" id="brand-boxes">
                <div className="container-fluid">
                    <div className="container">
                        <div className="section-head">
                            <div className="heading head-3">Shop By Brand</div>
                            <Link className="view-more" to="/viewmore/brands">
                                View More
                            </Link>
                        </div>
                        <div className="brand-grid">
                            <div className="brand-carousel ">


                                <OwlCarousel items={5}

                                    className="owl-theme"


                                    {...forBrands}

                                    margin={8} >


                                    {brands.slice(0, 10).map(brand =>


                                        <div key={brand.id} className="brand-box">
                                            <Link to={`/product-list/brand/${brand.id}`}>
                                                <div className="brand-img">
                                                    <img src={URL.brandPhotoUrl + brand.image} alt="..."
                                                        className="w-100 h-100" />
                                                </div>
                                                <div className="brand-name text-truncate">
                                                    {brand.brand_name}
                                                </div>
                                            </Link>
                                        </div>


                                    )}


                                </OwlCarousel>




                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* {{-- =============================================
        BRAND BOXES
============================================  --}}         */}

            <section className="home-section" id="doctor-boxes">
                <div className="container-fluid">
                    <div className="container">
                        <div className="section-head">
                            <div className="heading head-4">Our Doctors</div>
                            <Link className="view-more" to="/find-doctors">
                                View More
                            </Link>
                        </div>
                        <div className="doctor-grid">
                            <div className="doctor-carousel ">


                                <OwlCarousel

                                    className="owl-theme"
                                    {...fordoctor}

                                >


                                    {doctors.slice(0, 10).map(doctor =>


                                        <div key={doctor.id} className="doctor-box">
                                            <Link to={`/doctor-profile/${doctor.id}`}>
                                                <div className="doctor-img">
                                                    <img src={doctor.image ? URL.teamsPhotoUrl + doctor.image : 'assets/images/doctor/avatar.jpg'} alt="..."
                                                        className="w-100 h-100" />
                                                </div>
                                                <div className="doctor-name text-truncate">
                                                    {doctor.name}
                                                </div>
                                            </Link>
                                        </div>


                                    )}


                                </OwlCarousel>




                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* {{-- =============================================
                    REMEDIES BOXES
            ============================================  --}}
            */}

            <section className="home-section bg-back" id="remedy-boxes">
                <div className="container-fluid">
                    <div className="container">
                        <div className="section-head">
                            <div className="heading head-5">Health Library</div>
                            <Link className="view-more" to="/remedies">
                                View More
                            </Link>
                        </div>
                        <div className="brand-grid">
                            <div className="remedy-carousel">


                                <OwlCarousel items={4}

                                    className="owl-theme"
                                    {...forRemedy}

                                >


                                    {blogs.slice(0, 10).map(blog =>

                                        <div key={blog.id}>
                                            <Link to={`/remedy-details/${blog.id}`}>
                                                <div className="remedy-card">
                                                    <div className="remedy-img">
                                                        <img src={URL.blogsPhotoUrl + blog.image} alt="..."
                                                            className="w-100 h-100" />
                                                    </div>
                                                    <div className="remedy-content">
                                                        <div className="remedy-head text-truncate">
                                                            {blog.name}
                                                        </div>
                                                        <div className="remedy-data">
                                                            {Parser().parse(blog.des)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>


                                    )}

                                </OwlCarousel>





                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* {{-- =============================================
        TESTIMONIALS
============================================  --}} */}

            <section className="home-section" id="testimonials"
                style={{ backgroundImage: `url('assets/images/test-bg.jpg')` }}>
                <div className="container-fluid">
                    <div className="container">
                        <div className="section-head">
                            <div className="heading">Voice of Customers</div>
                            <div className="heading-bottom">
                                <div className="line"></div>
                                <div className="seperator"></div>
                                <div className="line"></div>
                            </div>
                        </div>
                        <div className="testimonial-grid">
                            <div className="testimonial-carousel">

                                <OwlCarousel items={1}

                                    className="owl-theme"
                                    loop

                                    nav

                                    margin={8} >


                                    <div>
                                        <div className="testimonial-card">
                                            <div className="user-info">
                                                <div className="image">
                                                    <img src="assets/images/doctor/user.png" alt="..."
                                                        className="w-100 h-100" />
                                                </div>
                                                <div className="name">Neha Sharma </div>
                                            </div>
                                            <div className="review">
                                                The Customer Service is too good....I like this app for buy medicine
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="testimonial-card">
                                            <div className="user-info">
                                                <div className="image">
                                                    <img src="assets/images/doctor/user.png" alt="..."
                                                        className="w-100 h-100" />
                                                </div>
                                                <div className="name">Aman Dev</div>
                                            </div>
                                            <div className="review">
                                                Fast delivery. Good service
                                            </div>
                                        </div>
                                    </div>




                                </OwlCarousel>


                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )



}
